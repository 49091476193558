import "./App.css";

import Weather from "./WeatherInfo/Weather";
const App = () => {
  return (
    <div>
      <Weather />
    </div>
  );
};

export default App;
